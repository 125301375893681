import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IMemberLoginDialogControllerProps,
  IMemberLoginDialogMapperProps,
  IMemberLoginDialogStateValues,
} from '../MemberLoginDialog.types';

export default withCompController<
  IMemberLoginDialogMapperProps,
  IMemberLoginDialogControllerProps,
  never,
  IMemberLoginDialogStateValues
>(({ mapperProps, stateValues }) => {
  return {
    ...mapperProps,
    ...stateValues,
  };
});
