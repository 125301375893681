import * as React from 'react';
import { ErrorSmall } from '@wix/wix-ui-icons-common/on-stage';
import { ErrorMessageType } from '@wix/editor-elements-types/components';
import style from '../style/inlineErrorMessage.scss';

export interface InlineErrorMessageProps {
  errorMessage?: string;
  errorMessageType: ErrorMessageType;
  shouldShowValidityIndication: boolean;
}

export const InlineErrorMessage: React.FC<InlineErrorMessageProps> = ({
  errorMessage,
  errorMessageType,
  shouldShowValidityIndication,
}) => {
  const hasInlineErrorMessage =
    errorMessageType === 'inline' && shouldShowValidityIndication;

  return hasInlineErrorMessage ? (
    <div className={style.inlineErrorIndication}>
      <ErrorSmall className={style.iconErrorMessage} />
      <span className={style.txtErrMsg}>
        {errorMessage ? errorMessage : 'Please fill out this field.'}
      </span>
    </div>
  ) : null;
};
