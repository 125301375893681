import { withCompController } from '@wix/editor-elements-integrations';
import {
  ISignUpDialogControllerProps,
  ISignUpDialogStateValues,
} from '../SignUpDialog.types';

export default withCompController<
  Record<string, any>,
  ISignUpDialogControllerProps,
  never,
  ISignUpDialogStateValues
>(({ mapperProps, stateValues }) => {
  return { ...mapperProps, ...stateValues };
});
